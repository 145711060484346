export const kr = {
  kr: {
    translation: {
      'header': '크레스티드 게코 모프 계산기',
      'what is morph': '해당 계산기에서 "모프"란 ',
      'allele': '대립유전자로 유전되는 것',
      'morph which cannot calc': ' 을 의미합니다. 할리퀸, 핀, 월 등은 ',
      'can not calc': '계산할 수 없습니다.',
      'if input nothing': '값을 넣지 않으면 ',
      'normal': '노말',
      'will be calc': '로 계산됩니다.',
      'het is': '헷 66%, 헷 50% 등은 유전 모프가 아닌 ',
      'having a morph': '해당 개체가 모프를 가질 확률을 나타내는 표기법',
      'as': '으로 ',
      'list occurred by':
        '모프 리스트는 MorphMarket의 리스트 일부와 모프 검증이 거의 완료된 것들을 위주로 넣었습니다.',
      'is not affected sex':
        '크레스티드 게코는 성별에 따라 확률이 달라지는 모프가 없습니다! ',
      'free to type it': '아무쪽에나 편하게 입력해주세요!',
      'reporting': '궁금증이나 버그 제보 및 모프 추가는 ',
      'instagram': '인스타그램 DM',
      'or kakaotalk':
        ' 혹은 같은 톡방에 계시거나 카톡 친구가 되어있다면 편하게 말씀 주세요:)',
      'donate': '개발자에게 커피 한 잔 후원하기',
      'calculating': '계산하기',
      'male': '수컷',
      'female': '암컷',
      'aa': '아잔틱',
      'bb': '패턴리스',
      'cc': '팬텀',
      'dd': '슈퍼 스트라이프',
      'ee': '블루 아이',
      'ff': '초초',
      'Aa': '헷 아잔틱',
      'Bb': '헷 패턴리스',
      'Cc': '헷 팬텀',
      'Dd': '헷 슈퍼 스트라이프',
      'Ee': '헷 블루 아이',
      'Ff': '헷 초초',
      'Gg': '릴리 화이트',
      'Hh': '카푸치노',
      'Ii': '소프트 스케일',
      'Jj': '세이블',
      'Kk': '하이포',
      'GG': '슈퍼 릴리 화이트',
      'HH': '슈퍼 카푸치노',
      'II': '슈퍼 소프트 스케일',
      'JJ': '슈퍼 세이블',
      'KK': '슈퍼 하이포',
      '아잔틱': '아잔틱',
      '패턴리스': '패턴리스',
      '팬텀': '팬텀',
      '슈퍼 스트라이프': '슈퍼 스트라이프',
      '블루 아이': '블루 아이',
      '초초': '초초',
      '헷 아잔틱': '헷 아잔틱',
      '헷 패턴리스': '헷 패턴리스',
      '헷 팬텀': '헷 팬텀',
      '헷 슈퍼 스트라이프': '헷 슈퍼 스트라이프',
      '헷 블루 아이': '헷 블루 아이',
      '헷 초초': '헷 초초',
      '릴리 화이트': '릴리 화이트',
      '카푸치노': '카푸치노',
      '소프트 스케일': '소프트 스케일',
      '세이블': '세이블',
      '하이포': '하이포',
      '슈퍼 릴리 화이트': '슈퍼 릴리 화이트',
      '슈퍼 카푸치노': '슈퍼 카푸치노',
      '슈퍼 소프트 스케일': '슈퍼 소프트 스케일',
      '슈퍼 세이블': '슈퍼 세이블',
      '슈퍼 하이포': '슈퍼 하이포',
      '열성 모프': '열성 모프',
      '열성(헷) 모프': '열성(헷) 모프',
      '공우성 모프': '공우성 모프',
      '공우성(슈퍼폼) 모프': '공우성(슈퍼폼) 모프',
      'close': '닫기',
      'more': '더보기',
    },
  },
};
