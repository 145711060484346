export const en = {
  en: {
    translation: {
      'header': 'Crested Gecko Morph Calculator',
      'what is morph': 'In that calculator, "morph" means ',
      'allele': 'inherited as an allele. ',
      'morph which cannot calc': 'Harlequin, pin, wall, etc. ',
      'can not calc': 'Cannot be calculated.',
      'if input nothing': 'If no value is entered, it is calculated as ',
      'normal': 'normal',
      'will be calc': '',
      'het is': 'Het 66%, Het 50%, etc. are not genetic morphs. ',
      'having a morph':
        'It is a notation for the probability that the subject will have morphs. ',
      'as': 'Therefore, ',
      'list occurred by':
        "The Morph list mainly included some of MorphMarket's lists and those that have almost been verified.",
      'is not affected sex':
        "Crested Gecko doesn't have a morph whose probability varies by gender.",
      'free to type it': '',
      'reporting': 'If you have any questions or add bugs and morphs, please ',
      'instagram': 'Instagram DM',
      'or kakaotalk': '',
      'donate': 'Buy Me a Cup of Coffee',
      'calculating': 'Calculate',
      'male': 'Male',
      'female': 'Female',
      'aa': 'Axanthic',
      'bb': 'Patternless',
      'cc': 'Phantom',
      'dd': 'Super Stripe',
      'ee': 'Blue eyes',
      'ff': 'Chocho',
      'Aa': 'Het Axanthic',
      'Bb': 'Het Patternless',
      'Cc': 'Het Phantom',
      'Dd': 'Het Super Stripe',
      'Ee': 'Het Blue eyes',
      'Ff': 'Het Chocho',
      'Gg': 'Lilly White',
      'Hh': 'Cappuccino',
      'Ii': 'Soft Scale',
      'Jj': 'Sable',
      'Kk': 'Hypo',
      'GG': 'Super Lilly White',
      'HH': 'Super Cappuccino',
      'II': 'Super Soft Scale',
      'JJ': 'Super Sable',
      'KK': 'Super Hypo',
      '아잔틱': 'Axanthic',
      '패턴리스': 'Patternless',
      '팬텀': 'Phantom',
      '슈퍼 스트라이프': 'Super Stripe',
      '블루 아이': 'Blue eyes',
      '초초': 'Chocho',
      '헷 아잔틱': 'Het Axanthic',
      '헷 패턴리스': 'Het Patternless',
      '헷 팬텀': 'Het Phantom',
      '헷 슈퍼 스트라이프': 'Het Super Stripe',
      '헷 블루 아이': 'Het Blue eyes',
      '헷 초초': 'Het Chocho',
      '릴리 화이트': 'Lilly White',
      '카푸치노': 'Cappuccino',
      '소프트 스케일': 'Soft Scale',
      '세이블': 'Sable',
      '하이포': 'Hypo',
      '슈퍼 릴리 화이트': 'Super Lilly White',
      '슈퍼 카푸치노': 'Super Cappuccino',
      '슈퍼 소프트 스케일': 'Super Soft Scale',
      '슈퍼 세이블': 'Super Sable',
      '슈퍼 하이포': 'Super Hypo',
      '열성 모프': 'Recessive Morph',
      '열성(헷) 모프': 'Recessive(Het) Morph',
      '공우성 모프': 'Co-dominant Morph',
      '공우성(슈퍼폼) 모프': 'Co-dominant(Super) Morph',
      'close': 'close',
      'more': 'more',
    },
  },
};
